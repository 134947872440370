import { useState, useRef } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function WorkHistory({
    id,
    createdAt,
    jobTitle= "",
    employer= "", 
    city= "", 
    country= "", 
    startDate= "", 
    endDate = "", 
    currentJob= false,
    jobDescription= "",
    formData,
    setFormData
}) {
    const { t } = useTranslation('site');
    
    const [wJobTitle, setWJobTitle] = useState(jobTitle);
    const [wEmployer, setWEmployer] = useState(employer);
    const [wCity, setWCity] = useState(city);
    const [wCountry, setWCountry] = useState(country);
    const [wStartDate, setWStartDate] = useState(startDate);
    const [wEndDate, setWEndDate] = useState(endDate);
    const [wCurrentJob, setWCurrentJob] = useState(currentJob);
    const [wJobDescription, setWJobDescription] = useState(jobDescription);

    const workHistoryState = {
        id,
        createdAt,
        jobTitle: wJobTitle,
        employer: wEmployer,
        city: wCity,
        country: wCountry,
        startDate: wStartDate,
        endDate: wEndDate,
        jobDescription: wJobDescription,
    };

    const updateJobTitle = (e) => {
        setWJobTitle(e.target.value);
        const oldWorkHitories = formData.workHistories.filter(el => el.id !== id);
        const newWorkHistory = { ...workHistoryState, jobTitle: e.target.value };
        const updatedWorkHisotries = [...oldWorkHitories, newWorkHistory].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, workHistories: updatedWorkHisotries });

    }

    const updateEmployer = (e) => {
        setWEmployer(e.target.value);
        const oldWorkHitories = formData.workHistories.filter(el => el.id !== id);
        const newWorkHistory = { ...workHistoryState, employer: e.target.value };
        const updatedWorkHisotries = [...oldWorkHitories, newWorkHistory].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, workHistories: updatedWorkHisotries });

    }

    const updateCity = (e) => {
        setWCity(e.target.value);
        const oldWorkHitories = formData.workHistories.filter(el => el.id !== id);
        const newWorkHistory = { ...workHistoryState, city: e.target.value };
        const updatedWorkHisotries = [...oldWorkHitories, newWorkHistory].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, workHistories: updatedWorkHisotries });

    }

    const updateCountry = (e) => {
        setWCountry(e.target.value);
        const oldWorkHitories = formData.workHistories.filter(el => el.id !== id);
        const newWorkHistory = { ...workHistoryState, country: e.target.value };
        const updatedWorkHisotries = [...oldWorkHitories, newWorkHistory].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, workHistories: updatedWorkHisotries });

    }

    const updateStartDate = (e) => {
        setWStartDate(e.target.value);
        const oldWorkHitories = formData.workHistories.filter(el => el.id !== id);
        const newWorkHistory = { ...workHistoryState, startDate: e.target.value };
        const updatedWorkHisotries = [...oldWorkHitories, newWorkHistory].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, workHistories: updatedWorkHisotries });

    }

    const updateEndDate = (e) => {
        setWEndDate(e.target.value);
        const oldWorkHitories = formData.workHistories.filter(el => el.id !== id);
        const newWorkHistory = { ...workHistoryState, endDate: e.target.value };
        const updatedWorkHisotries = [...oldWorkHitories, newWorkHistory].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, workHistories: updatedWorkHisotries });

    }

    const updateCurrentJob = (e) => {
        
        setWCurrentJob(!wCurrentJob);
        const oldWorkHitories = formData.workHistories.filter(el => el.id !== id);
        const newWorkHistory = { ...workHistoryState, currentJob: !wCurrentJob};
        const updatedWorkHisotries = [...oldWorkHitories, newWorkHistory].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, workHistories: updatedWorkHisotries });


    }

    const updateJobDescription = (e) => {
        setWJobDescription(e.target.value);
        const oldWorkHitories = formData.workHistories.filter(el => el.id !== id);
        const newWorkHistory = { ...workHistoryState, jobDescription: e.target.value };
        const updatedWorkHisotries = [...oldWorkHitories, newWorkHistory].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, workHistories: updatedWorkHisotries });

    }

    const removeWorkHistory = () => {
        const oldWorkHistories = formData.workHistories.filter(el => el.id !== id);
        const updatedWorkHistories = [...oldWorkHistories].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, workHistories: updatedWorkHistories });
    }
    
    return (
        <div className="flex flex-wrap pt-5">
            <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('job_title')}
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={wJobTitle}
                        onChange={(e) => updateJobTitle(e)}
                        
                    />
                </div>
            </div>
            <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('employer')}
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={wEmployer}
                        onChange={(e) => updateEmployer(e)}
                        
                    />
                </div>
            </div>
            <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('city')}
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={wCity}
                        onChange={(e) => updateCity(e)}
                    />
                </div>
            </div>
            
            <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('country')}
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={wCountry}
                        onChange={(e) => updateCountry(e)}
                            
                    />
                </div>
            </div>

            <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('start_date')}
                    </label>
                    <input
                        type="date"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={wStartDate}
                        onChange={(e) => updateStartDate(e)}
                            
                    />
                </div>
            </div>

            <div className="w-full lg:w-6/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                        {t('end_date')}
                    </label>
                    <input
                        type="date"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={wEndDate}
                        onChange={(e) => updateEndDate(e)}
                    />
                    <label>
                        <input type="checkbox" onChange={(e) => updateCurrentJob(e)} /> {t('currently_work_here')}
                    </label>
                </div>
            </div>

            <div className="w-full lg:w-12/12 px-4 ">
                <div className="relative w-full mb-5">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('add_job_description')}
                    </label>
                    <textarea
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder={t('achievement')}
                        rows="4"
                        value={wJobDescription}
                        onChange={(e) => updateJobDescription(e)}
                    ></textarea>
                </div>
            </div>

            <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full flex justify-end">
                    {formData.workHistories.length === 1 ? (
                        <button onClick={() => removeWorkHistory()} disabled="disabled" style={{ cursor: 'not-allowed', float: 'right' }} >
                            <FontAwesomeIcon icon={faTrash} style={{ color: "gray" }} />
                        </button>
                    ): (
                        <button onClick={() => removeWorkHistory()} >
                            <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
                        </button>
                    )}
                    
                </div>
            </div>
            

            
            
        </div>
    )
} 
export default WorkHistory;