import ReactPDF, {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    Font,
  } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
  
const styles = StyleSheet.create({
  body: {
    fontFamily: 'Arial',
  },
  borderBlue: {
    border: '15px solid #01A6C2',
    width:'100%',
    height: '100%',
    position: 'relative'
  },
  borderBlueDoc: {
    border: '15px solid #01A6C2',
    width:'100%',
    position: 'relative'
  },
  tinoBox: {
    width: '300px',
    backgroundColor: '#01A6C2',
    padding: '10px',
    position: 'absolute',
    top: '100px',
    right: 0
  },
  titleBox: {
    width: '500px',
    backgroundColor: '#01A6C2',
    padding: '20px',
    position: 'absolute',
    top: '300px',
    left: 0,
    lineHeight: 1.5
  },
  textBaseWhite : {
    fontSize: '18px',
    color: 'white'
  },
  textLgWhite : {
    fontSize: '25px',
    color: 'white'
  },
  textXlWhite: {
    fontSize: '30px',
    color: 'white',
  },
  textXlWhiteBold: {
    fontSize: '30px',
    color: 'white',
    fontWeight: 'bold'
  },
  text2XlWhiteBold: {
    fontSize: '40px',
    color: 'white',
    fontWeight: 'bold'
  }
  

});

// const companyName = "company Name";
// const jobTitle = "Chauffeur C/E (m/w/d)";
// const gender = "Mr.";
// const firstname = "Maksym";
// const lastname = "Onistrai";
// const experience = "5";
// const matching_degree = 60;
// const cvQualificationsTxt = "Höhere Berufsbildung, Deutsch, Chauffeur/euse, Logistik / Transportbereich";
// const jobQualificationsTxt = "";
// const otherPositions = "";
// const diploma = "Führerschein B,B1,C,C1,D,D1,BE,C1E,CE,DE";
// const languages = [
//   {
//     language: "Ukranian",
//     level: "Good"
//   },
//   {
//     language: "english",
//     level: "Good"
//   },
//   {
//     language: "french",
//     level: "Good"
//   }
// ];




const JobFolderPdf = ({ formData, jobTitle, matchingDegree, companyName }) => { 
  const { t } = useTranslation('site');


  // const { cvId, jobId, jobTitle } = useSelector(state => state.searchInput);
  // console.log({ cvId });
  
  // const jobList = useSelector(state => state.jobList, shallowEqual);
  // const jobs = jobList.find(j => j.cvId === cvId);
  // const selectedJob = jobs.jobList.find(j => j.target_profile_id === jobId);
  // const { matchingDegree, company } = selectedJob;
  // const {name: companyName} = company;

  const selectedDiploma = formData.diplomas.find(d => d.isChecked === true);
  

  return (
    <Document>
      <Page style={styles.body} size="A4"  >
        <View style={styles.borderBlue} >
          <view style={styles.tinoBox}>
            <Text style={styles.textBaseWhite}>Tino Senoner</Text>
            <Text style={styles.textXlWhiteBold}>dynajobs AG</Text>
          </view>
          <view style={styles.titleBox}>
            <Text style={styles.text2XlWhiteBold}>{companyName}</Text>
            <Text style={styles.textLgWhite}>{jobTitle}</Text>
          </view>
          <view style={{position: 'absolute',bottom:0,left:'200px', padding: '20px'}}>
            <Text style={{ fontSize: '18px', color: '#01A6C2', fontWeight: 'bold', marginBottom: '20px',paddingLeft: '20px' }}>Kontakte</Text>
            <Text style={{ fontSize: '14px', color: '#01A6C2', fontWeight: 'normal' }}><Image src="/images/company.png" style={{ width: '20px', marginRight: '10px', paddingTop: '10px' }} />dynajobs AG</Text>
            <Text style={{fontSize: '14px', color: '#01A6C2', fontWeight: 'normal'}}><Image src="/images/manager.png" style={{width:'20px', marginRight: '10px',paddingTop: '10px'}}/>Herr Tino Senoner</Text>
            <Text style={{fontSize: '14px', color: '#01A6C2', fontWeight: 'normal'}}><Image src="/images/email.png" style={{width:'20px', marginRight: '10px',paddingTop: '10px'}}/>tino.senoner@dynajobs.com</Text>
            <Text style={{fontSize: '14px', color: '#01A6C2', fontWeight: 'normal'}}><Image src="/images/phone.png" style={{width:'20px', marginRight: '10px',paddingTop: '10px'}}/>+41 79 240 39 01</Text>

          </view>
        </View>
      </Page>
      <Page style={styles.body} size="A4"  >
        <View style={{position: 'relative', width: '100%', height: '15%',backgroundColor: "#007090",padding: '20px'}} >
          <Text style={{ color: 'white', fontSize: '35px', fontWeight: 'bold' }}>{`${formData.firstname} ${formData.lastname}`}</Text>
          <Text style={{ color: 'white', fontSize: '20px' }} >{jobTitle}</Text>
          <view style={{ position: 'absolute', top: '50px', right: '0', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', padding: '10px',backgroundColor: 'white' }}>
            <Text style={{ color: '#007090', fontSize: '14px' }} >Beilage Profil</Text>
          </view>
        </View>
        <View style={{position: 'relative',paddingBottom:'10px', lineHeight:'2',width:'100%', height: '85%'}}>
          <view style={{ padding: '20px' }}>
            <Text style={{ fontSize: '18px', fontWeight: 'bold' }}>Experience: {formData.experience } Years</Text>
            <Text style={{ fontSize: '14px', fontWeight: 'bold' }}>Matching degree = {matchingDegree }</Text>
            <Text style={{ fontSize: '14px'}}>Bringt mit : <Text style={{ fontSize: '12px', fontWeight: 'normal' }}>{formData.cvQualifications }</Text></Text>
            <Text style={{ fontSize: '14px'}}>Zu verbessern : <Text style={{ fontSize: '12px', fontWeight: 'normal' }}>{formData.jobQualifications }</Text></Text>
            <Text style={{ fontSize: '14px' }}>Weitere Stellen im Unternehmen : <Text style={{ fontSize: '12px', fontWeight: 'normal' }}>{formData.otherPositions}</Text></Text>
            <Text style={{ fontSize: '18px', fontWeight: 'bold' }}>Mapping Graphik</Text>
            {formData.qualificationsImg && <Image src={ formData.qualificationsImg } style={{ width: '100%', padding: '20px 0'}} />}
            
            <view style={{ display: 'flex', flexDirection: 'row'}}>
              <view style={{ width: '50%' }}>
                <Text style={{ fontSize: '14px', display: 'block' }}>Diploma : </Text>
                <Text style={{ fontSize: '12px', fontWeight: 'normal' }}>{selectedDiploma?.title}</Text>
              </view>
              <view style={{ width: '50%' }}>
                <Text style={{ fontSize: '14px' }}>Sprachen : </Text>
                {formData.languages.map((l,index) => <Text key={index} style={{ fontSize: '12px' }}><view style={{width: '10px', height: '10px', backgroundColor: 'black',borderRadius: '50%',marginRight: '20px'}}></view>{`${l.language} ${l.level}`}</Text>)}
              </view>
            </view>
            
          </view>
          <view style={{ position: 'absolute', bottom: '0', width: '100%',padding: '10px', backgroundColor: '#007090'}}>
            <Text style={{textAlign:'right',fontSize: '20px', color: 'white', fontWeight: 'bold'}}>dynajobs AG</Text>
          </view>
        </View>
      </Page>
      
      {formData.cvImgs.length > 0 && formData.cvImgs.map(cvImg =>
        <Page style={styles.body} key={cvImg.id}  >
        <View style={styles.borderBlue} >
          <View style={{position: 'relative', width: '100%', height: '15%',padding: '20px'}} >
            <Text style={{ color: '#01A6C2', fontSize: '35px', fontWeight: 'bold' }}>{`${formData.firstname} ${formData.lastname}`}</Text>
            <Text style={{ color: '#01A6C2', fontSize: '20px' }} >Lebenslauf</Text>
            <view style={{ position: 'absolute', top: '40px', right: '0', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', padding: '10px',backgroundColor: '#01A6C2' }}>
              <Text style={{ color: 'white', fontSize: '14px' }} >Lebenslauf</Text>
            </view>
          </View>
          <View style={{ paddingTop: '10px' }}>
            <Image  src={ cvImg.src } style={{ width: '100%', padding: '20px 0'}} />
          </View>
        </View>
      </Page>
      )
      }

      {/* <Page style={styles.body}  >
        <View style={styles.borderBlueDoc} >
          <View style={{position: 'relative', width: '100%', height: '15%',padding: '20px'}} >
            <Text style={{ color: '#01A6C2', fontSize: '35px', fontWeight: 'bold' }}>{`${formData.firstname} ${formData.lastname}`}</Text>
            <Text style={{ color: '#01A6C2', fontSize: '20px' }} >Zertifikat</Text>
            <view style={{ position: 'absolute', top: '40px', right: '0', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', padding: '10px',backgroundColor: '#01A6C2' }}>
              <Text style={{ color: 'white', fontSize: '14px' }} >Zertifikat</Text>
            </view>
          </View>
          <View style={{ padding: '20px' }}>
            {formData.diplomas.map(d => {
              const { title, university, startDate, endDate } = d;
              return (
                <View style={{ padding: '20px'}}>
                  <Text style={{ fontSize: '14px', color: "#01A6C2" }}>
                    <Image src="/images/university.png" style={{ width: '20px', marginRight: '10px', paddingTop: '10px' }} />
                    University : {university}
                  </Text>
                  <Text style={{ fontSize: '14px', color: "#01A6C2" }}>
                    <Image src="/images/diploma.png" style={{ width: '20px', marginRight: '10px', paddingTop: '10px' }} />
                    Diploma : {title}
                  </Text>
                  <Text style={{ fontSize: '14px', color: "#01A6C2" }}>
                    <Image src="/images/calendar.png" style={{ width: '20px', marginRight: '10px', paddingTop: '10px' }} />
                    Date : {startDate} - {endDate}
                  </Text>
                </View>
              )
            })}
            {formData.diplomaImgs.map(diplomaImg=><Image key={diplomaImg.id} src={ diplomaImg.src } style={{ width: '100%', padding: '20px 0'}} />)}
          </View>
        </View>
        
      </Page> */}
      {formData.diplomaImgs.length > 0 && formData.diplomaImgs.map((diplomaImg,index) =>
        <Page style={styles.body} key={diplomaImg.id} >
          <View style={styles.borderBlue} >
            <View style={{position: 'relative', width: '100%', height: '15%',padding: '20px'}} >
              <Text style={{ color: '#01A6C2', fontSize: '35px', fontWeight: 'bold' }}>{`${formData.firstname} ${formData.lastname}`}</Text>
              <Text style={{ color: '#01A6C2', fontSize: '20px' }} >Zertifikat</Text>
              <view style={{ position: 'absolute', top: '40px', right: '0', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', padding: '10px',backgroundColor: '#01A6C2' }}>
                <Text style={{ color: 'white', fontSize: '14px' }} >Zertifikat</Text>
              </view>
            </View>
            <View style={{ padding: '20px' }}>
              {index===0 && formData.diplomas.map(d => {
                const { title, university, startDate, endDate } = d;
                return (
                  <View style={{ padding: '20px'}}>
                    <Text style={{ fontSize: '14px', color: "#01A6C2" }}>
                      <Image src="/images/university.png" style={{ width: '20px', marginRight: '10px', paddingTop: '10px' }} />
                      University : {university}
                    </Text>
                    <Text style={{ fontSize: '14px', color: "#01A6C2" }}>
                      <Image src="/images/diploma.png" style={{ width: '20px', marginRight: '10px', paddingTop: '10px' }} />
                      Diploma : {title}
                    </Text>
                    <Text style={{ fontSize: '14px', color: "#01A6C2" }}>
                      <Image src="/images/calendar.png" style={{ width: '20px', marginRight: '10px', paddingTop: '10px' }} />
                      Date : {startDate} - {endDate}
                    </Text>
                  </View>
                )
              })}
              <Image src={ diplomaImg.src } style={{ width: '100%', padding: '10px 0', maxHeight:'600px'}} />
            </View>
          </View>
          
        </Page>
      )}
    </Document>
  );
}

Font.register({
  family: 'Arial',
  fonts: [
    {
      fontWeight: 'normal'
    },
    {
      fontWeight: 'bold'
    }
  ]
});



export default JobFolderPdf;
  