import React from 'react'
import HeaderStats from "components/Headers/HeaderStats.js";
import HorizontalBarChart from "components/Charts/HorizontalBarChart.js";
import RadarChart from "components/Charts/RadarChart.js";
import EmptyChart from "components/Charts/EmptyChart.js";
import ActionButons from "components/Buttons/ActionButtons.js";
import { useSelector } from "react-redux";
import Mapper from '../../utils/mapper';
import MapperPercentage from '../../utils/mapperPercentage';
import trainingMapper from 'utils/trainingMapper';
import MapChart from 'components/Charts/MapChart';
import { useTranslation } from 'react-i18next';
import translateRubrications from '../../utils/translateRubrications';





function MatchingJobsCV1Section() {
  const { t,i18n } = useTranslation('site');
  const lang = i18n.language.substring(0, 2);
  let { cvId, jobId, resultNumber, cvRubricationData, jobRubricationData, trainingRubricationData } = useSelector(state => state.searchInput);

  cvRubricationData = translateRubrications(cvRubricationData,lang);
    jobRubricationData = translateRubrications(jobRubricationData,lang);
    trainingRubricationData = translateRubrications(trainingRubricationData,lang);

  const jobList = useSelector(state => state.jobList);
  const jobs = jobList?.find(j => j.cvId === cvId);
  const dataJobList = jobId ?jobs?.jobList:[];
  
  const updatedCvs = useSelector(state => state.updatedCvs);
  const updatedCv = updatedCvs.find(d => d.cvId === cvId && d.jobId === jobId);
  const updatedCvRubrications = updatedCv ? updatedCv.cvRubrications : [];


  const sortedCvRubrications = cvRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);

  const cvQualifications = resultNumber > 0 ? sortedCvRubrications.slice(0, 10) : sortedCvRubrications;
  
  const cvQualificationsData = {
    label: "",
    data: cvQualifications,
    backgroundColor: 'rgb(3, 194, 252)'
  }
  
  const cvQualificationsHeader = resultNumber > 0 ? t('TOP_10 _CV_qualifications') : t('all_cv_qualifications');

  

  const sortedJobRubrications = jobRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);
  const jobQualifications = resultNumber > 0 ? sortedJobRubrications.slice(0, 10) : sortedJobRubrications;
  const jobQualificationsData = {
      label: "",
      data: jobQualifications,
      backgroundColor: 'rgb(69, 112, 63)'
  }

  const jobQualificationsHeader = resultNumber > 0 ? t('top_10_job_qualifications') : t('all_job_qualifications');

  const cssClass = jobRubricationData.length > 0 ?'w-full xl:w-6/12 mb-12 xl:mb-0 px-2 mx-auto': 'w-full';
    
  
  const height = resultNumber === '' ? 250 : 200;
  const jobCvMapping = Mapper(jobQualifications, cvRubricationData);
  const updatedJobCvMapping = Mapper(jobQualifications, updatedCvRubrications);
  const jobMappingData = {
    label: "",
    data: jobCvMapping,
    backgroundColor: 'rgba(3, 194, 252,0.5)'
  }

  const jobMappingPercentage = MapperPercentage(jobCvMapping);
  const updatedJobMappingPercentage = updatedCv ?MapperPercentage(updatedJobCvMapping):'N.A';

  const jobCvTrainingMapping = updatedCv ?trainingMapper(jobQualifications, updatedJobCvMapping, trainingRubricationData) :trainingMapper(jobQualifications, cvRubricationData, trainingRubricationData);
    const trainingMappingPercentage = trainingRubricationData.length>0 ?MapperPercentage(jobCvTrainingMapping):'N.A';
    
    const headerStatsData = [jobMappingPercentage, updatedJobMappingPercentage, trainingMappingPercentage];
  // const headerStatsData = [jobMappingPercentage, updatedJobMappingPercentage, 'N.A'];
  return (
    <>
      {cvId && <div>
        <HeaderStats data={headerStatsData} />
        <div className="flex flex-wrap ">
          <div className="container w-full mb-12 xl:mb-0 px-4 mx-auto">
          <div className="flex flex-wrap">
              <HorizontalBarChart cssClass={cssClass} data={[cvQualificationsData]} subheader={t('CV_ANALYSIS')} header={cvQualificationsHeader} height={height}/>
              {jobRubricationData.length > 0 && <HorizontalBarChart cssClass={cssClass} data={[jobQualificationsData]} subheader={t('JOB_ANALYSIS_Menu')} header={jobQualificationsHeader} height={height} />}
        
            {jobRubricationData.length > 0 && <RadarChart height={200} header={t('cv_job_mapping')} data={[jobMappingData]} /> }
            {jobRubricationData.length > 0 && <MapChart header={t('Jobs_loaction')} data={dataJobList}/> }
            
            </div>
          </div>
        </div>
        </div>}

    </> 
  )
}

export default MatchingJobsCV1Section