import React from 'react';
import axiosClient from "api/axiosClient";
import UploadCV from "components/Modals/UploadCV"
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { addJob } from "redux/reducer/jobListReducer";
import { addJobOptions } from "redux/reducer/jobOptionsReducer";
import { setCvRubricationData } from "redux/reducer/searchReducer";
import { setJobTitle } from "redux/reducer/searchReducer";
import { setShowLoader } from "redux/reducer/searchReducer";
import { setTrainingRubricationData } from "redux/reducer/searchReducer";
import { setJobRubricationData } from "redux/reducer/searchReducer";
import { setTrainingId } from "redux/reducer/searchReducer";
import { setJobId } from "redux/reducer/searchReducer";
import { setCvId } from "redux/reducer/searchReducer";
import { showAddCv } from "redux/reducer/addCvReducer";

const SelectCv = () => {
    const { t } = useTranslation('site');
    const dispatch = useDispatch();
    let {
        cvId,
        cvRubricationData
    } = useSelector(state => state.searchInput, shallowEqual);
    const cvList = useSelector(state => state.cvList, shallowEqual);
    
    const getCvRubrication = (cvId) => {
        dispatch(setShowLoader(true));
        axiosClient.get(`/Rubrication/GetCVRubricationById?cvid=${cvId}`).then(res => {
                const { rubrication_items } = res.data;
                dispatch(setCvRubricationData(rubrication_items));
                dispatch(setShowLoader(false));
                
        }).catch(err => {
            dispatch(setCvRubricationData([]));
            dispatch(setShowLoader(false));
        }); 
    }

    const selectCvHandler = (e) => {
        
        const selectedCvId = e.target.value;
        dispatch(setCvId(selectedCvId));
        dispatch(setJobId(''));
        dispatch(setJobTitle(''));
        dispatch(addJob([]));
        dispatch(addJobOptions([]));
        dispatch(setJobRubricationData([]));
        dispatch(setTrainingId(''));
        dispatch(setTrainingRubricationData([]));

        if (selectedCvId) {
            getCvRubrication(selectedCvId);

        }
        
    }
    /*React.useEffect(() => {
        const select_cv = document.querySelector('#cv_list');
          select_cv.dispatchEvent(new Event('change', { bubbles: true }));
    }, []);*/
    return (
        <>
            <div className="w-5/12 ">
                <div className="py-2">
                    <label htmlFor="cv_list" className="font-semibold">{t('Select_a_cv')}</label> or
                    <button className="ml-2 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-semibold uppercase text-sm px-2 py-1  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" onClick={()=>dispatch(showAddCv(true))}>ADD CV</button>
                </div>
                <select
                    id="cv_list"
                    className="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline w-full max-w-full"
                    onChange={selectCvHandler}
                    value={cvId}
                >
                    <option value=""></option>
                    {cvList && cvList.length > 0 && cvList.map((cv, index) => <option key={index} value={cv.profile_id}>{cv.title}</option>)}
                </select>    
            </div>
            
        </>
    )
}
export default SelectCv;