import React from "react";








function HeaderSection({ header, subheader}) {



    return (
        
        <div>
            <div className="relative bg-lightBlue-600 -mt-10  pb-16 ">
                <div className="px-4 md:px-10 mx-auto w-full">
                    <h1 className="text-3xl text-center	font-normal leading-normal mt-0 mb-2 text-white">{header}
                    </h1>
                    <h4 className=" text-2xl text-center	font-normal leading-normal mt-0 mb-2 text-white">{ subheader }</h4>
                </div>
            </div>

        </div>
    )
}

export default HeaderSection;