
const MapperPercentage = (data) => {
    if (data.length === 0) return 'N.A';

    const value = data.reduce((sum, curr) => {

        const { ranking_score } = curr;
        if (ranking_score > 0) {
            
            return sum + 1;
        }
        return sum;
        
    }, 0);
    const percentage = (value / data.length * 100).toFixed(0);
    return percentage+'%';
}
export default MapperPercentage;