import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ActionButtons = () => {
    const {t}=useTranslation('site');
    return (
        <div className="flex justify-between w-full xl:w-8/12 mb-12 xl:mb-0 px-4 pb-4 mx-auto">
            <Link className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-sm px-4 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" to="/matching-jobs">{t('cv_job_mapping')}</Link>
            <Link className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-sm px-4 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" to="/boost-chances">{t('Completed_CV_mapping')}</Link>
            <Link className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-sm px-4 py-4  rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-150 mt-5" to="/boost-training">{t('Boosted_by_training')}</Link>
        </div>
    );
}

export default ActionButtons;