/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import dynaskillsLogo from '../../assets/img/dynaskillsuprising.png'
import { addCvList } from "redux/reducer/cvListReducer";
import { useDispatch } from "react-redux";
import Loading from "components/Loadings/Loading";



export default function ForgetPassword() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

    
    async function requestPasswordReset(e) {
        setShowLoader(true);
        setErrorMessage("");
        e.preventDefault();
        //const url = 'https://ukranian-sunrise-server.herokuapp.com/api/login';
        const url = process.env.REACT_APP_BACKEND_DYNASKILLS+'/requestResetPassword';
        
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email })
        });
        const data = await response.json();
        if (response.status === 500) {
            console.log(data)
            setErrorMessage(data.error);
            setShowLoader(false);

        }

        if (response.status === 200) {
            setShowSuccess(true);
            setShowLoader(false);

        }
  }

  
  
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex justify-between w-full">
          <h2 className="blueTextDark text-lg font-bold relative">
            Candidate
          </h2>
          <div className="blueTextDark text-lg text-center font-bold relative">
          No account already?<br/>
            <Link to="/auth/register" className="text-center" style={{textDecoration: 'underline'}} >
               Sign up
            </Link>
          </div>
        </div>
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 border-0">
            <div className="flex justify-center mb-10">
                <img src={dynaskillsLogo} alt="Ukranian Sunrise" className="ml-10" style={{width:"280px", height:"auto"}}/>
              </div>
              <div className="text-center mb-10">
                <h6 className="blueTextDark text-2xl font-bold">
                Reset your password
                </h6>
                
              </div> 
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              {showSuccess ? <div className="bg-green-400 mb-5 px-4 text-center py-5 rounded text-white">
              Please check your email inbox for a link to complete the reset.
              </div> : <form onSubmit={requestPasswordReset}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase blueTextDark text-xs font-bold mb-2"
                      htmlFor="grid-password"
                      
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 blueTextDark bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>

                  
                  

                  <div className="text-center mt-6">
                    <button
                      className="blueDarkBg text-white flex justify-center items-center gap-5 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit" 
                    >
                      { showLoader ? <>Loading <Loading width="30" height="30" className="inline-flex" color="#fff" /></> : `Reset your password`}
                    </button>
                    <div className="text-red-500">{errorMessage}</div>
                                      
                    
                  </div>
                  
                </form>}
                
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
