import RightMenuDropdown from "components/Dropdowns/RightMenuDropdown";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { showAlert } from "redux/reducer/alertReducer";
import { setPremium } from "redux/reducer/isPremiumReducer";
import LogoDynaskills from "../../assets/img/dynaskillsuprisingwhite.png";
import "../../assets/styles/counter.css";

const Welcome = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation('site');
    const cvList = useSelector(state => state.cvList, shallowEqual);
    const isPremium = useSelector(state => state.isPremium);
    const changeLanguageHandler = (e) =>
    {
        i18n.changeLanguage(e.target.value)
    }
    const alert  = useSelector(state => state.alert);

    return (
        <div className="container relative  pt-10 pb-20">
            {isPremium ? (
                <div className="absolute right-0 top-0 flex gap-2  items-center py-2 mb-10  font-semibold text-xl " style={{ color: '#ebc351' }}>
                    <i className="fa fa-crown" ></i>
                    <span>{ t('premium_version') }</span>
                </div>
            ) : (
                <button className="absolute right-0 top-0 flex gap-2 shadow-2xl items-center py-2 px-4 rounded-lg text-white font-semibold text-lg bg-lightBlue-500" onClick={(e)=>dispatch(setPremium(false))} >
                <i className="fa fa-crown" style={{ color: '#ebc351' }}></i>
                <span>Try dynaskills® Premium</span>
            </button>
            )}
            {!isPremium && <div className="mx-auto flex flex-col items-center gap-5 relative" style={{ width: '500px' }} >
                    <div className="counter text-lightBlue-500">
                        <div className="counter-icon"><i className="fa fa-file"></i></div>
                        <span className="counter-value">{3 - cvList.length}</span>
                        <h3>Trials left</h3>
                    </div>
                    {alert && <div className="bg-lightBlue-500 border-l-4 border-lightBlue-800 text-white py-4 px-6 relative" role="alert">
                        <button className="absolute top-2 right-2 cursor-pointer text-lightBlue-800" onClick={(e)=>dispatch(showAlert(false))}>
                            <i className="fa fa-times"></i></button>
                            <p>You have already used all your 3 cv trials, to upload more <br/><a href='#' className="underline text-lightBlue-800" style={{fontWeight: 'bold'}}>go to premium</a></p>
                    </div>}
                </div>
                
            }
            <div className="flex justify-between items-center mt-10">
                <span className="text-lg text-white font-semibold">{t('welcome_to')}</span>
            </div>
            <div className="flex justify-between items-end mt-10">
                
                <img
                    className="w-48 h-auto"
                    src={LogoDynaskills}
                    alt="..."
                />
                <select className="ml-3  placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm border border-blueGray-300 outline-none focus:outline-none focus:shadow-outline font-semibold" onChange={e=>changeLanguageHandler(e)} value={i18n.language.substring(0, 2)}>
                    <option value="de">{t('german')}</option>
                    <option value="en">English</option>
                    <option value="uk">Ukranian</option>
                    <option value="fr">French</option>
                    <option value="it">Italian</option>
                    <option value="ar">Arabic</option>
                    <option value="pt">Portuguese</option>
                    <option value="ur">Urdu</option>
                    <option value="zh">Chinese</option>
              </select>
            </div>
            <div>

            </div>
        </div>  
    );
}

export default Welcome;

