import React from "react";

import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";

export default function Navbar({ title }) {
  const {t,i18n}= useTranslation('site');


  const lang = i18n.language.substring(0, 2);
  return (
    <>
      {/* Navbar */}
      <nav className="fixed top-0 left-0 w-full z-10 bg-lightBlue-800 md:flex-row md:flex-nowrap md:justify-start flex items-center h-16 shadow-2xl" style={{zIndex:"9999"}}>
        <div className="container mx-auto items-center flex justify-between md:flex-nowrap flex-wrap ">
          <div className="flex gap-3 items-center flex justify-between md:flex-nowrap flex-wrap">
            <a
              className=" text-white text-lg md:text-sm  items-center font-semibold bg-lightBlue-500 px-2 py-5 h-20 flex gap-2 z-10"
              href={"https://dynaskills-dynajobs.com/help?lang=" + lang}
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faWrench} /> <span>{t('support')}</span>
            </a>
            
          </div>
          <div className=" flex flex-wrap w-96">
              
          </div>
          <div className="flex gap-3 items-center">
            <Link
              className=" text-white text-lg md:text-sm font-semibold"
              to="contact"
            >
              {t('contact_us')}
            </Link>
            <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
              <UserDropdown />
            </ul>
          </div>
          
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
