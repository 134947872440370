import { useState, useRef } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function SocialLink({ id, name = "", link = "", createdAt,formData, setFormData }) {
    const { t } = useTranslation('site');
    const [socialName, setsocialName] = useState(name);
    const [socialLink, setsocialLink] = useState(link);
    const updateName = (e) => {
        setsocialName(e.target.value);
        const oldSocialLinks = formData.socialLinks.filter(el => el.id !== id);
        const newSocialLink = { id, createdAt, link: socialLink, name: e.target.value };
        const updatedSocialLinks = [...oldSocialLinks, newSocialLink].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, socialLinks: updatedSocialLinks });

    }

    const updateLink = (e) => {
        setsocialLink(e.target.value);
        const oldSocialLinks = formData.socialLinks.filter(el => el.id !== id);
        const newSocialLink = { id, createdAt, link: e.target.value, name: socialName };
        const updatedSocialLinks = [...oldSocialLinks, newSocialLink].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, socialLinks: updatedSocialLinks });
    }

    const removeSocialLink = () => {
        const oldSocialLinks = formData.socialLinks.filter(el => el.id !== id);
        const updatedSocialLinks = [...oldSocialLinks].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, socialLinks: updatedSocialLinks });
    }
    
    return (
        <div className="flex flex-wrap">
            <div className="w-full lg:w-5/12 px-4">
                <div className="relative w-full mb-3">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                        
                    >
                        {t('name')}
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={socialName}
                        onChange={(e) => updateName(e)}
                    />
                </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                    >
                    {t('link')}
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={socialLink}
                        onChange={(e) => updateLink(e)}
                    />
                </div>
            </div>
            <div className="w-full lg:w-1/12 px-4">
                <div className="relative w-full pt-9">
                    {formData.socialLinks.length === 1 ? (
                        <button onClick={() => removeSocialLink()} disabled="disabled" style={{ cursor: 'not-allowed' }} >
                            <FontAwesomeIcon icon={faTrash} style={{ color: "gray" }} />
                        </button>
                    ): (
                        <button onClick={() => removeSocialLink()} >
                            <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
                        </button>
                    )}
                    
                </div>
            </div>
        </div>
    )
} 
export default SocialLink;