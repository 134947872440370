

const SET_USER = 'set_user';

export const setUser = () => ({
    type: SET_USER
});

const initialState = {
    authorisation: ''
    
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            const { authorisation } = action
            return { ...state, authorisation };
        default:
            return state;
    }
}