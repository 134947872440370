import React from "react";
import { v4 as uuid } from 'uuid';

const DiplomaImg = ({formData, setFormData, id, createdAt, imgSrc}) => {

    const file = React.useRef(null);
    const label = React.useRef(null);
    const labelText = React.useRef(null);
    const labelRemove = React.useRef(null);


    const diplomaImgObjectState = {
        id,
        createdAt,
        src: imgSrc
    };

    
    const getBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let result = this.result;

            const oldDiplomaImgs = formData.diplomaImgs.filter(el => el.id !== id);
            const newDiplomaImg = { ...diplomaImgObjectState, src: result };
            const updatedDiplomaImgs = [...oldDiplomaImgs, newDiplomaImg].sort((a,b)=>a.createdAt - b.createdAt);
            setFormData({ ...formData, diplomaImgs: updatedDiplomaImgs });

        }
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }
      }

    const fileHandler = (e) => {

        const fileName = String.raw`${e.target.value}`.split('\\').pop();
        if (fileName) {
            labelText.current.innerHTML = fileName;
            labelRemove.current.style.display = 'block';
            getBase64(e.target.files[0])
        } else {
            labelText.current.innerHTML = '';
            labelRemove.current.style.display = 'none';
        }
    }

    const removeFile = () => {
        const oldDiplomaImgs = formData.languages.filter(el => el.id !== id);
        const updatedDiplomaImgs = [...oldDiplomaImgs].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, diplomaImgs: updatedDiplomaImgs });

        file.current.value = "";
        labelText.current.innerHTML = '';
        labelRemove.current.style.display = 'none';
    }

    const idInput = uuid();
    return (
        <div className="w-32">
            <input type="file" id={idInput} ref={file} onChange={e => fileHandler(e)} style={{ display: 'none' }} />
            <label htmlFor={idInput} className="bg-blueGray-300 flex items-center py-2 px-5 gap-5 cursor-pointer" ref={label}>
                <span>Upload</span>
                <i className="fa fa-paperclip "></i>

            </label>
            <div className="text-center  flex items-center">
                <span ref={labelText}></span>
                <i className="fa fa-trash remove cursor-pointer ml-2" ref={labelRemove} onClick={removeFile} style={{ display: 'none' }}></i>
            </div>
        </div>
    )
}
export default DiplomaImg;