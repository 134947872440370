import axiosClient from "api/axiosClient";
import Table from "components/Cards/Table";
import UploadCV from "components/Modals/UploadCV"
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setCvRubricationData } from "redux/reducer/searchReducer";
import { setShowLoader } from "redux/reducer/searchReducer";
import { setTrainingRubricationData } from "redux/reducer/searchReducer";
import { setJobRubricationData } from "redux/reducer/searchReducer";
import { setTrainingId } from "redux/reducer/searchReducer";
import { setJobId } from "redux/reducer/searchReducer";
import { setCvId } from "redux/reducer/searchReducer";
import missingItems from "utils/missingItems";
import translateRubrications from "utils/translateRubrications";
import HorizontalBarChart from "./../../Charts/HorizontalBarChart"
const SelectCv = () => {
    const { t,i18n } = useTranslation('site');
    const lang = i18n.language.substring(0, 2);
    
    let { cvId, jobId, resultNumber, cvRubricationData, jobRubricationData, trainingRubricationData } = useSelector(state => state.searchInput);
    
    cvRubricationData = translateRubrications(cvRubricationData,lang);
    const sortedJobRubrications = jobRubricationData.sort((a, b) => b.ranking_score - a.ranking_score);
    const jobQualifications = resultNumber > 0 ? sortedJobRubrications.slice(0, 10) : sortedJobRubrications;
    const missingInCv = missingItems(jobQualifications, cvRubricationData);
    const missingInCvData = {
        label: "",
        data: missingInCv,
        backgroundColor: 'rgb(85, 171, 114)'
    }
    return (
        <>
            <div className="w-12/12 ">
                
                {/* <Table cssClass="w-12/12 px-2" data={missingInCv} /> */}
            </div>
        </>
    )
}
export default SelectCv;