

const trainingMapper = (data1 = [], data2 = [], data3 = []) => {
    const data = data1.map(d => {
        let { ranking_score, sub_category_name, sub_category_id } = d;
        const itemCv = data2.find(elm => elm.sub_category_id === sub_category_id);

        if (!itemCv || itemCv.ranking_score === 0) {

            const itemTraining = data3.find(e => e.sub_category_id === sub_category_id);
            if (!itemTraining) {
                ranking_score= 0
            }else {
                ranking_score=itemTraining.ranking_score;
            }
        } else {
            ranking_score=itemCv.ranking_score;
        }
        
        return { ranking_score, sub_category_name, sub_category_id };
    });
    return data;
}

export default trainingMapper;