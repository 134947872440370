import React from 'react';



  
function BarChart({header}) {

    

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              
              <h2 className="text-blueGray-700 text-xl font-semibold">
              {header} 
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto h-350-px">
          {/* Chart */}
          <div className="relative ">
          </div>
        </div>
      </div>
    </>
  );
}

export default BarChart;