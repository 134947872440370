import translateCategory from 'utils/translateCategory'
import translateSubCategory from 'utils/translateSubCategory'

const translateRubrications = (rubrication_items,lang) => {
    const rubrications = rubrication_items.map(r=>{
        const category_name = translateCategory(r.category_id,lang,r.category_name);
        const sub_category_name = translateSubCategory(r.sub_category_id,lang,r.sub_category_name);
        return {...r,category_name,sub_category_name};
    });
    return rubrications;
}

export default translateRubrications;