import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

const styles = StyleSheet.create({
  body: {
    fontFamily: 'Oswald',
    paddingBottom: 65,
  },
  title: {
    fontSize: 24,
    marginLeft:20
  },
  label: {
    fontSize: 12,
    fontFamily: 'Oswald',
    marginTop: 10,
    marginLeft: 20,
    fontWeight: 'bold',
  },
  labelBlue: {
    fontSize: 12,
    fontFamily: 'Oswald',
    marginTop: 10,
    marginLeft: 20,
    fontWeight: 'bold',
    color: '#0284c7'
  },
    jobTitle: {
    fontSize: 12,
    fontFamily: 'Oswald',
    marginLeft:20
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    fontSize: 12,
    marginLeft: 20
  },
  textBlack: {
    fontSize: 12,
    marginLeft: 20,
    color: '#000000'
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  section: {
    color: 'white',
    textAlign: 'left',
    backgroundColor: '#0284c7',
    width: '35%',
    paddingTop: 20,
    flex: 1,
  },
  sectionRight: {
    color: 'black',
    textAlign: 'left',
    backgroundColor: '#fff',
    width: '65%',
    paddingTop: 20,
    paddingRight: 10,
    flex: 2
  },
  subSection: {
    color: 'white',
    backgroundColor: '#B2C8E8',
    width: '100%',
    marginTop: 40,
    paddingTop: 10,
    paddingBottom: 10
  },
  subSectonTitle: {
    fontSize: 18,
    fontFamily: 'Oswald',
    marginLeft:20
  },
  image: {
    paddingLeft:20
  },
  container: {
    flexDirection: "row",
    flex: 1
  },
  rightSubSection: {
    marginTop: 20,
    color: '#0284c7',
    border: '1px solid #EDEDED',
    borderLeft: 0,
    borderRight: 0,
    paddingTop: 10, paddingBottom: 10
    
  },
  line: {
    marginTop: 20,
    marginBottm: 20,
    border: '1px solid #EDEDED',

    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    width:'100%'
  }

});

// const formData = JSON.parse(localStorage.getItem('generatedCv'));
// console.log(formData);

const generateStars = (number) => {
  const stars = [];
  for (let i = 0; i < number; i++) {
    stars.push(<Image key={i}
      style={styles.image}
      src="/images/star.png"
    />);
  }
  return stars;
}

const CvPdf = ({ formData }) => {
  const { t } = useTranslation('site');
  return (
  <Document>
    <Page style={styles.body} size="A4"  >
      <View style={styles.container} >
        <View style={styles.section} >
          <Text style={styles.title}>{formData.firstname +' '+ formData.lastname}</Text>
          <Text style={styles.jobTitle}>{formData.actualJob}</Text>
          <View style={styles.subSection}>
            <Text style={styles.subSectonTitle}>{t('contact')}</Text>
          </View>
          <Text style={styles.label}>
            {t('address')}: <Text style={styles.text}> {formData.country + ', ' + formData.city + ' ' + formData.postalCode} </Text>
          </Text>
          <Text style={styles.label}>{t('phone')} : <Text style={styles.text}>{formData.phone}</Text></Text>
          <Text style={styles.label}>{t('email')}: <Text style={styles.text}>{formData.email}</Text></Text>
          {formData.socialLinks.length > 0 && formData.socialLinks.map((s, index) => (
            <Text key={index} style={styles.label}>{s.name} : <Text style={styles.text}>{s.link}</Text></Text>
          ))}
          <View style={styles.subSection}>
            <Text style={styles.subSectonTitle}>{t('skills')}</Text>
          </View>
          {formData.skills.length > 0 && formData.skills.map((s, index) => (
            <Text style={styles.label} key={index}>
            {s.skillName} :{'\u00A0'}
            {generateStars(s.rating)}
            </Text>
          ))}
          
          
          
          <View style={styles.subSection}>
            <Text style={styles.subSectonTitle}>{t('languages')}</Text>
          </View>
          {formData.languages.length > 0 && formData.languages.map((l, index) => (
            <Text style={styles.label} key={index}>{l.language} : {'\u00A0'}{ l.level }</Text>
          ))}
        </View>
        <View style={styles.sectionRight}  >
          <Text style={styles.text} >{formData.professionalSummary}</Text>
          <View style={styles.rightSubSection}>
            <Text style={styles.subSectonTitle}>
            {t('work_history')}
            </Text>
          </View>
          {formData.workHistories.length > 0 && formData.workHistories.map((w, index) => (
            <>
              {index >0 && <View style={styles.line}></View>}
              <Text style={styles.labelBlue} key={index}>
                {t('job_title')} : <Text style={styles.textBlack}>{w.jobTitle} ({w.startDate} - {w.endDate ? w.endDate : t('now') })</Text>
              </Text>
              <Text style={styles.labelBlue} key={index}>
                {t('employer')} : <Text style={styles.textBlack}>{w.employer}</Text>
              </Text>
              <Text style={styles.labelBlue} key={index}>
                {t('location')} : <Text style={styles.textBlack}>{w.country+', '+w.city}</Text>
              </Text>
              
              <Text style={styles.labelBlue} key={index}>
                {t('job_description')} : <Text style={styles.textBlack}>{w.jobDescription}</Text>
              </Text>
              
            </>
          ))}
          <View style={styles.rightSubSection}>
            <Text style={styles.subSectonTitle}>
            {t('education')}
            </Text>
          </View>
          {formData.officialTitles.length > 0 && formData.officialTitles.map((o, index) => (
            <>
              {index >0 && <View style={styles.line}></View>}
              <Text style={styles.labelBlue} key={index}>
                {t('official_title')} : <Text style={styles.textBlack}>{o.officialTitle + ' '+ o.year}</Text>
              </Text>
              <Text style={styles.labelBlue} key={index}>
                {t('school')} : <Text style={styles.textBlack}>{o.school}</Text>
              </Text>
            </>
          ))}
          {formData.furtherEducations.length > 0 && formData.furtherEducations.map((o, index) => (
            <>
              <View style={styles.line}></View>
              <Text style={styles.labelBlue} key={index}>
                {t('further_education')} : <Text style={styles.textBlack}>{o.furtherEducation + ' '+ o.year}</Text>
              </Text>
              <Text style={styles.labelBlue} key={index}>
              {t('school')} : <Text style={styles.textBlack}>{o.school}</Text>
              </Text>
            </>
          ))}
          
        </View>
        
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
      `${pageNumber} / ${totalPages}`
      )} fixed />
      
    </Page>
  </Document>
)
};

Font.register({
family: 'Oswald',
src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});



export default CvPdf;





