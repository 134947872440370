const SET_CHECKED_QUALIFICATIONS = 'set_checked_qualifications';


export const setCheckedQualifications = (cvId, jobId, checkedQualifications, cvRubrications,plusText) => ({
    type: SET_CHECKED_QUALIFICATIONS,
    cvId,
    jobId,
    checkedQualifications,
    cvRubrications,
    plusText
});




const initialState = [];


export default function updatedCvsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CHECKED_QUALIFICATIONS:
            const {
                cvId,
                jobId,
                checkedQualifications,
                cvRubrications,
                plusText
            } = action;
            const uniqueId = cvId+jobId
            const newState = state.filter(d => d.uniqueId !== uniqueId);
            
            const item = {
                uniqueId,
                cvId,
                jobId,
                checkedQualifications,
                cvRubrications,
                plusText
            };

            return [...newState, item];
        default:
            return state;
    }
}