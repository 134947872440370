import { useState} from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function Language({ id, language = "", level = "", createdAt, formData, setFormData }) {
    const { t } = useTranslation('site');
    
    const [languageState, setLanguageState] = useState(language);
    const [levelState, setLevelState] = useState(level);

    const languageObjectState = {
        id,
        createdAt,
        language: languageState,
        level: levelState,
    };
    
    const updateLanguage = (e) => {
        setLanguageState(e.target.value);
        const oldLanguages = formData.languages.filter(el => el.id !== id);
        const newLanguage = { ...languageObjectState, language: e.target.value };
        const updatedLanguages = [...oldLanguages, newLanguage].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, languages: updatedLanguages });

    }

    const updateLevel = (e) => {
        
        setLevelState(e.target.value);
        const oldLanguages = formData.languages.filter(el => el.id !== id);
        const newLanguage = { ...languageObjectState, level: e.target.value };
        const updatedLanguages = [...oldLanguages, newLanguage].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, languages: updatedLanguages });

    }

    

    const removeLanguage = () => {
        const oldLanguages = formData.languages.filter(el => el.id !== id);
        const updatedLanguages = [...oldLanguages].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, languages: updatedLanguages });
    }
    

    return (
        <div className="flex justify-end relative">
            <div className="w-4/12">
                <input
                    type="text"
                    className="w-full rounded h-10 bg-blueGray-200 border-0" 
                    value={languageState}
                    onChange={(e) =>
                        updateLanguage(e)
                    }
                />
            </div>

            <div className="w-4/12 flex justify-end pr-5 pt-4"  >
                {t('level')}
            </div>
            <div className="w-4/12">
                <input 
                    type="text"
                    className="w-full rounded h-10 bg-blueGray-200 border-0" 
                    value={levelState}
                    onChange={(e) =>
                        updateLevel(e)
                    }
                 />
            </div>
            
            <div className="absolute -right-7 top-2">
                {formData.languages.length === 1 ? (
                    <button onClick={() => removeLanguage()} disabled="disabled" style={{ cursor: 'not-allowed' }} >
                        <FontAwesomeIcon icon={faTrash} style={{ color: "gray" }} />
                    </button>
                ): (
                    <button onClick={() => removeLanguage()} >
                        <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
                    </button>
                )}
                
            </div>
        </div>
    )

    
} 
export default Language;