import React from "react";
import Sidebar from 'components/Sidebar/Sidebar';
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import BoostCareerSection from 'components/Sections/BoostCareerSection';
import { useSelector } from "react-redux";
import Loading from 'components/Loadings/Loading'
import SearchSection from "components/Sections/SearchSection";
import { useTranslation } from 'react-i18next';










function BoostByTraining() {
    const { t } = useTranslation('site');

    const { cvId, showLoader } = useSelector(state => state.searchInput);
    
    return (
        <>
            <Sidebar />
            <div className="relative md:ml-64 bg-blueGray-100">
                <AdminNavbar title={t('ADDED_VALUE_TRAINING')} />
                <SearchSection headers={[t('What_do_I_get_out_of_the_training'),t('Qualification_analysis_select')]} showTraining={true} />
                {showLoader ? <Loading/> : cvId ? <BoostCareerSection /> : ''}
            </div>
            <FooterAdmin />
        </>
    )
}

export default BoostByTraining;