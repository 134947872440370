import { useState, useRef } from "react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function OfficialTitle({ id, officialTitle = "", school = "", year = "", createdAt, formData, setFormData }) {
    
    const { t } = useTranslation('site');

    const [officialTitleState, setOfficialTitleState] = useState(officialTitle);
    const [schoolState, setSchoolState] = useState(school);
    const [yearState, setYearState] = useState(year);

    const officialTitleObjectState = {
        id,
        createdAt,
        officialTitle: officialTitleState,
        school: schoolState,
        year: yearState,
    };


    const updateOfficialTitle = (e) => {
        setOfficialTitleState(e.target.value);
        const oldOfficialTitles = formData.officialTitles.filter(el => el.id !== id);
        const newOfficialTitle = { ...officialTitleObjectState, officialTitle: e.target.value };
        const updatedOfficialTitles = [...oldOfficialTitles, newOfficialTitle].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, officialTitles: updatedOfficialTitles });

    }

    const updateSchool = (e) => {
        setSchoolState(e.target.value);
        const oldOfficialTitles = formData.officialTitles.filter(el => el.id !== id);
        const newOfficialTitle = { ...officialTitleObjectState, school: e.target.value };
        const updatedOfficialTitles = [...oldOfficialTitles, newOfficialTitle].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, officialTitles: updatedOfficialTitles });
    }

    const updateYear = (e) => {
        setYearState(e.target.value);
        const oldOfficialTitles = formData.officialTitles.filter(el => el.id !== id);
        const newOfficialTitle = { ...officialTitleObjectState, year: e.target.value };
        const updatedOfficialTitles = [...oldOfficialTitles, newOfficialTitle].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, officialTitles: updatedOfficialTitles });
    }

    const removeOfficialTitle = () => {
        const oldOfficialTitles = formData.officialTitles.filter(el => el.id !== id);
        const updatedOfficialTitles = [...oldOfficialTitles].sort((a,b)=>a.createdAt - b.createdAt);
        setFormData({ ...formData, officialTitles: updatedOfficialTitles });
    }
    
    return (
        <div className="flex flex-wrap">
            <div className="w-full lg:w-5/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('official_title')}
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="expl: Bachelor of Laws / Electronician "
                        value={officialTitleState}
                        onChange={(e)=>updateOfficialTitle(e)}
                        
                    />
                    
                </div>
            </div>
            <div className="w-full lg:w-4/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('school_name_and_location')}
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="expl: Universty of Zurich Switzerland / Technical High School"
                        value={schoolState}
                        onChange={(e)=>updateSchool(e)}
                    />
                    
                </div>
            </div>
            
            <div className="w-full lg:w-2/12 px-4 ">
                <div className="relative w-full mb-10">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    >
                        {t('year')}
                    </label>
                    <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="expl: 1995"
                        value={yearState}
                        onChange={(e)=>updateYear(e)}
                    />
                </div>
            </div>
                
            <div className="w-full lg:w-1/12 px-4">
                <div className="relative w-full pt-9">
                    {formData.officialTitles.length === 1 ? (
                        <button onClick={() => removeOfficialTitle()} disabled="disabled" style={{ cursor: 'not-allowed' }} >
                            <FontAwesomeIcon icon={faTrash} style={{ color: "gray" }} />
                        </button>
                    ): (
                        <button onClick={() => removeOfficialTitle()} >
                            <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
                        </button>
                    )}
                    
                </div>
            </div>
        </div>
    )
} 
export default OfficialTitle;