import { createStore, combineReducers } from 'redux';
import cvListReducer from './reducer/cvListReducer';
import jobListReducer from './reducer/jobListReducer';
import jobOptionsReducer from './reducer/jobOptionsReducer';
import trainingListReducer from './reducer/trainingListReducer';
import userReducer from './reducer/userReducer';
import searchReducer from './reducer/searchReducer';
import updatedCvsReducer from './reducer/updatedCvsReducer';
import alertReducer from './reducer/alertReducer';
import addCvReducer from './reducer/addCvReducer';
import isPremiumReducer from './reducer/isPremiumReducer';
import sectionReducer from './reducer/sectionReducer';

const reducer = combineReducers({
    user: userReducer,
    cvList: cvListReducer,
    jobList: jobListReducer,
    jobOptions: jobOptionsReducer,
    trainingList: trainingListReducer,
    searchInput: searchReducer,
    updatedCvs: updatedCvsReducer,
    alert: alertReducer,
    addCv: addCvReducer,
    isPremium: isPremiumReducer,
    section: sectionReducer
});

const store = createStore(reducer);
export default store;