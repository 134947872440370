

const SET_PREMIUM = 'set_premium';



export const setPremium = (bool) => ({
    type: SET_PREMIUM,
    bool
});




const initialState = JSON.parse(localStorage.getItem('premium'));

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PREMIUM:
            const { bool } = action
            return bool;
        default:
            return state;
            
    }
}