

const missingItems = (data1 = [], data2 = []) => {
    const data = data1.filter(d => {
        let { ranking_score, sub_category_name, category_name, sub_category_id } = d;
        const item = data2.find(elm => elm.sub_category_id === sub_category_id);
        if (!item) {
            return { ranking_score, sub_category_name, category_name, sub_category_id };
        }
        return false;
        
    });
    return data;
}

export default missingItems;