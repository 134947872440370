import { useRef, useState } from "react";
import base64Encoder from "utils/base64Encoder";

const Base64 = () => {

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [result, setResult] = useState('');

    const generateCode = () => {

        const authorisation = base64Encoder(`${userName}:${password}`);
        setResult(authorisation);
    }
    return (
        <>
            <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)} placeholder="userName" />
            <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" />
            <button
                onClick={generateCode}
            >
                generate
            </button><br />
            <h2>{result}</h2>
        </>
    )
}

export default Base64;