import categories from 'trans/categories.json'
const translateCategory = (category_id, lang, fallback="") => {

    const rubrication = categories.find(item=>item.category_id==category_id);
    if(rubrication && rubrication[lang]) {
        return rubrication[lang];
    }
    return fallback;
}

export default translateCategory;