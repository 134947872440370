

const ADD_JOB_LIST = 'add_job_list';



export const addJob = (job_list) => ({
    type: ADD_JOB_LIST,
    job_list
});


const initialState = [];

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_JOB_LIST:
            const { job_list } = action;
            const newState = [job_list];
            return newState;
        default:
        return state;
            
    }
}