/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import usLogo from '../../assets/img/us-logo.png'
import dynaskillsLogo from '../../assets/img/dynaskillsuprising.png'
// import axiosClient from "../../api/axiosClient"
import { addCvList } from "redux/reducer/cvListReducer";
import axios from "axios";
import { useDispatch } from "react-redux";
import Loading from "components/Loadings/Loading";
// import { showAddCv } from "redux/reducer/addCvReducer";

export default function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  async function loginUser(e) {
    setErrorMessage("");
    setShowLoader(true);
    e.preventDefault();
    const url = process.env.REACT_APP_BACKEND_DYNASKILLS+'/login';
    await axios.post(url, {
      headers: {
        'Content-Type': 'application/json'
      },
      data: {
        username:email,
        password
      }
    }).then(response => {
      const data = response.data;
      if (data.user) {
      
        localStorage.setItem('token', data.token)
        localStorage.setItem('authorisation', data.user.authorization);
        localStorage.setItem('username', data.user.username);
        localStorage.setItem('email', data.user.email);
        localStorage.setItem('premium', data.user.premium);
        /*if (data.user.cvs.length === 0) {
          localStorage.setItem('show_add_cv', true);
        } 
        dispatch(addCvList(data.user.cvs))*/
        if (data.user.company) {
          if (data.user.cvs.length === 0) {
            localStorage.setItem('show_add_cv', true);
          }
          dispatch(addCvList(data.user.cvs));
          window.location.href = '/matching-jobs'
        } else {
          getCvList(data.user.authorization)
        }
        
        //setShowLoader(false);
  
        //getCvList(data.user.authorization)
        //window.location.href = '/matching-jobs'
        //history.push("/matching-jobs");
        
      } 
    }).catch(err => {
      console.log(err.response);
      setErrorMessage(err?.response?.data?.error);
    }).finally(() => {
      setShowLoader(false);
    });
    
    
  }

  async function getCvList(authorisation) {
      let cvList = [];
    await axios.get('https://mct-demo.dynajobs.com/api/CVManagement/GetUploadedCVs', {
      headers: {
        'Authorization': 'DYNAService '+authorisation
      }
    }).then(function (response) {
      console.log(response);
        if (response.data && response.data.length > 0) {
          cvList = response.data.map(data => {
            return {
              profile_id: data.cv_id,
              title: data.details.title
            }

          })
          
      }

      dispatch(addCvList(cvList))
      window.location.href = '/matching-jobs'
        
    }).finally(function () {
      setShowLoader(false);
      });
    
  }
  
  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex justify-between w-full">
          <h2 className="blueTextDark text-lg font-bold relative">
            Candidate
          </h2>
          <div className="blueTextDark text-lg text-center font-bold relative">
          No account already?<br/>
            <Link to="/auth/register" className="text-center" style={{textDecoration: 'underline'}} >
               Sign up
            </Link>
          </div>
        </div>
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 border-0">
            <div className="flex justify-center mb-10">
                <img src={dynaskillsLogo} alt="Ukranian Sunrise" className="ml-10" style={{width:"280px", height:"auto"}}/>
              </div>
              <div className="text-center mb-10">
                <h6 className="blueTextDark text-2xl font-bold">
                Sign In
                </h6>
                
              </div> 
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                
                <form onSubmit={loginUser}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase blueTextDark text-xs font-bold mb-2"
                      htmlFor="grid-password"
                      
                    >
                      Username
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 blueTextDark bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase blueTextDark text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 blueTextDark bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded blueTextDark ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold blueTextDark">
                        Remember me
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="blueDarkBg text-white flex justify-center items-center gap-5 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit" 
                    >
                      { showLoader ? <>Loading <Loading width="30" height="30" className="inline-flex" color="#fff" /></> : `Sign in`}
                    </button>
                    <p className="text-red-500">{errorMessage}</p>
                  </div>
                  <div className="flex flex-wrap mt-6 relative">
                    <div className="w-1/2">
                      <a
                        href="/auth/forget-password"
                        className="blueTextDark"
                      >
                        <small>Forgot password?</small>
                      </a>
                    </div>
                    
                  </div>
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="/auth/forget-password"
                  onClick={(e) => e.preventDefault()}
                  className="text-blueGray-200"
                >
                  <small>Forgot password?</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Create new account</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
