

const cvMapper = (data1 = [], data2 = []) => {
    const data = data1.map(d => {
        let { ranking_score, sub_category_name, sub_category_id } = d;
        const item = data2.find(elm => elm.sub_category_id === sub_category_id);//if cv 2 has this item
        if (!item) {
            ranking_score = 0;
        } else {
            ranking_score = item.ranking_score;
        }
        
        return { ranking_score, sub_category_name, sub_category_id };
    });
    return data;
}

export default cvMapper;